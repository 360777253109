import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as g } from "react";
import "./Accordion-CbzVXurW.js";
import "./Avatar-D2CKq5wD.js";
import { B as c } from "./Badge-C0bj4DFU.js";
import { B as d } from "./Button-DhTfdjdw.js";
import "./DropdownMenu-BOLwas6W.js";
import "./Icon-B2GFsdMo.js";
import "./Input-vl7Xx2ol.js";
import { L as x } from "./Label-BKL8LTmX.js";
import { T as r } from "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import { c as N } from "./utils-CJ9afRe1.js";
import { S as h } from "./Stack-9Jnn74Nm.js";
import "./Switch-B3f_4NTB.js";
import "./Toast-m-Gt1j1X.js";
import "./Toggle-DH_2vm0p.js";
import { A as f } from "./AlertDialog.component-C4dDhAuK.js";
import { D as C } from "./Drawer.component-DMxeZJ_Q.js";
import { R as n } from "./RadioGroup.component-D-umUTM9.js";
import { S as j } from "./Separator.component-CH2uOQZ5.js";
import { u as p } from "./useMediaQuery-BLIs2rKV.js";
const Q = (a) => {
  var m, l;
  const [i, u] = g(null), t = p("(min-width: 768px)"), o = t ? f : C;
  return /* @__PURE__ */ e.jsx(
    o,
    {
      onOpenChange: a.onOpenChange,
      open: a.open,
      children: /* @__PURE__ */ e.jsxs(
        o.Content,
        {
          className: "max-w-md z-full-screen",
          children: [
            /* @__PURE__ */ e.jsxs(r, { className: "px-3 pt-3", children: [
              "Upload",
              " ",
              a.items.length,
              " ",
              "items to ",
              (m = a.loan) == null ? void 0 : m.shortCode,
              " shoebox?"
            ] }),
            /* @__PURE__ */ e.jsx(j, {}),
            /* @__PURE__ */ e.jsx(o.Header, { className: N("px-4", {
              "text-center items-center justify-center": t,
              "text-left": !t
            }), children: /* @__PURE__ */ e.jsx(r, { size: t ? "base" : "sm", children: "You’re not on this loan. Please select the user's shoebox to add items or select yourself to join loan." }) }),
            /* @__PURE__ */ e.jsxs(
              n,
              {
                onValueChange: (s) => u(s),
                className: "px-2 flex flex-col gap-5",
                children: [
                  a.me && /* @__PURE__ */ e.jsxs(
                    h,
                    {
                      row: !0,
                      space: "sm",
                      className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                      children: [
                        /* @__PURE__ */ e.jsx(n.Item, { value: a.me.id, id: a.me.id }),
                        /* @__PURE__ */ e.jsxs(
                          x,
                          {
                            className: "flex-1 gap-1 flex items-center",
                            htmlFor: a.me.id,
                            children: [
                              a.me.givenName,
                              /* @__PURE__ */ e.jsx(r, { size: "sm", variant: "secondary", children: "(Me)" })
                            ]
                          }
                        ),
                        /* @__PURE__ */ e.jsx(c, { variant: "secondary", children: a.me.role })
                      ]
                    }
                  ),
                  /* @__PURE__ */ e.jsx(r, { size: "sm", className: "px-3", children: "Team" }),
                  (l = a.loan) == null ? void 0 : l.users.map((s) => /* @__PURE__ */ e.jsxs(
                    h,
                    {
                      row: !0,
                      space: "sm",
                      className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                      children: [
                        /* @__PURE__ */ e.jsx(n.Item, { value: s.id, id: s.id }),
                        /* @__PURE__ */ e.jsx(x, { className: "flex-1", htmlFor: s.id, children: s.name }),
                        /* @__PURE__ */ e.jsx(c, { variant: "secondary", children: s.role })
                      ]
                    },
                    s.id
                  ))
                ]
              }
            ),
            /* @__PURE__ */ e.jsx(j, {}),
            /* @__PURE__ */ e.jsxs(f.Footer, { children: [
              /* @__PURE__ */ e.jsx(
                d,
                {
                  onClick: () => {
                    var s;
                    return (s = a.onOpenChange) == null ? void 0 : s.call(a, !1);
                  },
                  className: "w-24",
                  variant: "outline",
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ e.jsx(
                d,
                {
                  className: "w-24",
                  onClick: () => {
                    i && a.loan && a.onConfirm(a.loan, i, a.items);
                  },
                  disabled: !i,
                  children: "Add"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  Q as A
};
